.container .contentArea {
  white-space: pre-line;
  margin-bottom: 60px;
  padding: 0;
  border-left: 8px solid #fe0217;
}
.container .contentArea::-webkit-scrollbar {
  display: none;
}

.container .closeButton {
  min-width: 40px;
  height: 40px;
  padding: 5px;
  border-radius: 20px;
  color: #ffffff;
}

.container .closeButton.error {
  background-color: #fe0217;
}

.dialogPaper {
  justify-content: flex-end;
  align-items: flex-end;
  margin: 0;
}

.alert {
  color: rgba(254, 2, 23, 0.88);
  font-size: 20px;
}

.alertIcon {
  width: 60px;
  height: 48px;
}
