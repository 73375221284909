.loadingBox {
  position: fixed;
  display: table;
  width: 100%;
  height: 100%;
  z-index: 2002;
}
.boxInner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
.boxBg {
  width: 80px;
  height: 80px;
}
.boxIcon {
  width: 100%;
  height: 100%;
}
