.container .success {
  background-color: #43a047;
}

.container .error {
  background-color: #f00;
}

.container .info {
  background-color: #0cf;
}

.container .warning {
  background-color: #d32f2f;
}

.container .message {
  display: flex;
  align-items: center;
}

.container .close {
  color: #fff;
  cursor: pointer;
}
