.children {
  display: inline-block;
  float: right;
  width: calc(100% - 80px);
}

.childrenShift {
  float: right;
  width: calc(100% - 240px);
}
