.header .mainHeader {
  /* border-bottom: solid 1px #fff; */
  background-color: #0098d1;
  color: #fff;
  border-color: #e7e7e7;
  padding: 0.5vh 10px;
  display: flex;
  /* height: 5vh; */
}
.header .mainHeaderShift {
  width: calc(100% - 240px);
}
.header .mainHeader .titleLink {
  cursor: pointer;
  color: #fff;
  width: 100px;
  height: 50%;
  /* margin-top: 10px; */
  margin-right: 10px;
  display: inline-flex;
  font-size: 30px;
}
.header .linkList {
  list-style: none;
  display: flex;
  font-size: 18px;
  align-items: center;
}
.header .linkList .linkArea {
  margin-right: 10px;
}
.header .headerRight {
  position: absolute;
  right: 10px;
  top: 25px;
}
.header .linkText {
  text-decoration: none;
  color: #aaa;
}
.header .linkText:visited {
  color: #aaa;
}
.header .linkText:hover {
  color: #777;
}
.header .email {
  display: flex;
  cursor: pointer;
}
