@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url(http://fonts.googleapis.com/earlyaccess/notosansjapanese.css);
@import url(http://fonts.googleapis.com/earlyaccess/notosansjp.css);

html {
  --mainColor: #0098d1;
  --backgroundColorMain: var(--mainColor);
  --fontColorMain: #ffffff;
}
html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Noto Sans JP', 'Noto Sans CJK JP', sans-serif;
  font-weight: 600;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
