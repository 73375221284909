.container .attentionArea {
  width: 100%;
  padding: 0 auto;
  margin-bottom: 16px;
  margin-top: 12px;
}
.container .attentionArea .attentionMark {
  margin: 0 auto;
  margin-bottom: 12px;
}
.container .attentionArea .attentionMessage {
  text-align: center;
  font-weight: bold;
}
.container .contentArea {
  white-space: pre-line;
  overflow-x: hidden;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 25px;
}
.container .contentArea::-webkit-scrollbar {
  display: none;
}
.container .contentArea .iframeContent {
  width: 100%;
  height: 100%;
  transform: scale(1, 1);
}
.container .contentArea .button {
  margin: 10px;
}
