@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);
@import url(http://fonts.googleapis.com/earlyaccess/notosansjapanese.css);
@import url(http://fonts.googleapis.com/earlyaccess/notosansjp.css);
.style_container__V9eNJ .style_contentArea__3MNe2 {
  white-space: pre-line;
  margin-bottom: 60px;
  padding: 0;
  border-left: 8px solid #fe0217;
}
.style_container__V9eNJ .style_contentArea__3MNe2::-webkit-scrollbar {
  display: none;
}

.style_container__V9eNJ .style_closeButton__15aWI {
  min-width: 40px;
  height: 40px;
  padding: 5px;
  border-radius: 20px;
  color: #ffffff;
}

.style_container__V9eNJ .style_closeButton__15aWI.style_error__3DWzd {
  background-color: #fe0217;
}

.style_dialogPaper__3MkMX {
  -moz-box-pack: end;
       justify-content: flex-end;
  -moz-box-align: end;
       align-items: flex-end;
  margin: 0;
}

.style_alert__3op2_ {
  color: rgba(254, 2, 23, 0.88);
  font-size: 20px;
}

.style_alertIcon__1IybG {
  width: 60px;
  height: 48px;
}

.style_container__1MrWi {
  width: 20px;
  height: 20px;
  background-color: #ff0925;
  color: #ffffff;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
}

.style_container__3M9J9 .style_attentionArea__1fIva {
  width: 100%;
  padding: 0 auto;
  margin-bottom: 16px;
  margin-top: 12px;
}
.style_container__3M9J9 .style_attentionArea__1fIva .style_attentionMark__1xkKt {
  margin: 0 auto;
  margin-bottom: 12px;
}
.style_container__3M9J9 .style_attentionArea__1fIva .style_attentionMessage__1GoHF {
  text-align: center;
  font-weight: bold;
}
.style_container__3M9J9 .style_contentArea__3pYmp {
  white-space: pre-line;
  overflow-x: hidden;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 25px;
}
.style_container__3M9J9 .style_contentArea__3pYmp::-webkit-scrollbar {
  display: none;
}
.style_container__3M9J9 .style_contentArea__3pYmp .style_iframeContent__2uihU {
  width: 100%;
  height: 100%;
  -webkit-transform: scale(1, 1);
     -moz-transform: scale(1, 1);
          transform: scale(1, 1);
}
.style_container__3M9J9 .style_contentArea__3pYmp .style_button__2eXgB {
  margin: 10px;
}

.style_header__1YNcn .style_mainHeader__27A7G {
  /* border-bottom: solid 1px #fff; */
  background-color: #0098d1;
  color: #fff;
  border-color: #e7e7e7;
  padding: 0.5vh 10px;
  display: -moz-box;
  display: flex;
  /* height: 5vh; */
}
.style_header__1YNcn .style_mainHeaderShift__EDe9o {
  width: -webkit-calc(100% - 240px);
  width: -moz-calc(100% - 240px);
  width: calc(100% - 240px);
}
.style_header__1YNcn .style_mainHeader__27A7G .style_titleLink__3RgO6 {
  cursor: pointer;
  color: #fff;
  width: 100px;
  height: 50%;
  /* margin-top: 10px; */
  margin-right: 10px;
  display: -moz-inline-box;
  display: inline-flex;
  font-size: 30px;
}
.style_header__1YNcn .style_linkList__1gQZP {
  list-style: none;
  display: -moz-box;
  display: flex;
  font-size: 18px;
  -moz-box-align: center;
       align-items: center;
}
.style_header__1YNcn .style_linkList__1gQZP .style_linkArea__25zzG {
  margin-right: 10px;
}
.style_header__1YNcn .style_headerRight__21pM9 {
  position: absolute;
  right: 10px;
  top: 25px;
}
.style_header__1YNcn .style_linkText__127oR {
  text-decoration: none;
  color: #aaa;
}
.style_header__1YNcn .style_linkText__127oR:visited {
  color: #aaa;
}
.style_header__1YNcn .style_linkText__127oR:hover {
  color: #777;
}
.style_header__1YNcn .style_email__3LbFP {
  display: -moz-box;
  display: flex;
  cursor: pointer;
}

.style_loadingBox__1VHrI {
  position: fixed;
  display: table;
  width: 100%;
  height: 100%;
  z-index: 2002;
}
.style_boxInner__35UiJ {
  display: -moz-box;
  display: flex;
  -moz-box-pack: center;
       justify-content: center;
  -moz-box-align: center;
       align-items: center;
  height: 100%;
  width: 100%;
}
.style_boxBg__3IskM {
  width: 80px;
  height: 80px;
}
.style_boxIcon__2i1Ao {
  width: 100%;
  height: 100%;
}

.style_container__1t6rF .style_success__20_Ca {
  background-color: #43a047;
}

.style_container__1t6rF .style_error__2Jj3q {
  background-color: #f00;
}

.style_container__1t6rF .style_info__3plx7 {
  background-color: #0cf;
}

.style_container__1t6rF .style_warning__fqfaM {
  background-color: #d32f2f;
}

.style_container__1t6rF .style_message__1ed7H {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
       align-items: center;
}

.style_container__1t6rF .style_close__Dha-g {
  color: #fff;
  cursor: pointer;
}

.common_children__16yEv {
  display: inline-block;
  float: right;
  width: -webkit-calc(100% - 80px);
  width: -moz-calc(100% - 80px);
  width: calc(100% - 80px);
}

.common_childrenShift__3A7Vw {
  float: right;
  width: -webkit-calc(100% - 240px);
  width: -moz-calc(100% - 240px);
  width: calc(100% - 240px);
}

html {
  --mainColor: #0098d1;
  --backgroundColorMain: var(--mainColor);
  --fontColorMain: #ffffff;
}
html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Noto Sans JP', 'Noto Sans CJK JP', sans-serif;
  font-weight: 600;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  -moz-box-sizing: border-box;
       box-sizing: border-box;
}

